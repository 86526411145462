import { isBuiltWithWix } from "@resamare/functions";
import { captureException, extraErrorDataIntegration, init } from "@sentry/react";

type InitSentryParams = {
    dsn: string;
    widgetName: string;
};

export const initSentry = ({ dsn, widgetName }: InitSentryParams) => {
    // Sentry is not working with Wix and I don't know why (maybe version not supported)
    if (isBuiltWithWix()) return;

    init({
        dsn,
        integrations: [extraErrorDataIntegration()],

        ignoreErrors: ["Network Error"],

        // Envoie seulement les erreurs provenant du js du widget
        // sinon sentry recupère toutes les erreurs du site
        beforeSend(event) {
            if (event.exception && event.exception.values) {
                const isFromMyFile = event.exception.values.every((exception) => {
                    if (exception.stacktrace && exception.stacktrace.frames) {
                        return exception.stacktrace.frames.every(
                            (frame) =>
                                frame.filename && frame.filename.endsWith(`${widgetName}/index.js`),
                        );
                    }
                    return false;
                });
                return isFromMyFile ? event : null;
            }
            return null;
        },
    });
};

interface ErrorWithContext extends Error {
    context?: Record<string, unknown>;
}

export const logError = (error: Error, errorInfo?: React.ErrorInfo) => {
    captureException(error, {
        extra: {
            componentStack: errorInfo?.componentStack || "",

            context: (error as ErrorWithContext).context || null,
        },
    });
};
