import {
    Button,
    Divider,
    Form,
    FormError,
    FormHidden,
    FormNumberStepper,
    Stack,
} from "@resamare/ui";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "@resamare/translation";

import { ObjectSchema } from "yup";
import { ParticipantType } from "@resamare/models";
import { LiaisonFormTripType } from "./LiaisonFormTripType";
import { LiaisonFormTravel } from "./LiaisonFormTravel";
import { LiaisonFormDates } from "./LiaisonFormDates";
import { LiaisonSearch, TripType } from "../../../../search";
import { DepartureLocation } from "../../../../../models";

export type LiaisonFormProps = {
    defaultValues: Partial<LiaisonSearch>;
    dense?: boolean;
    departureLocations: DepartureLocation[];
    isLoading?: boolean;
    onSubmit: SubmitHandler<LiaisonSearch>;
    participantTypes: ParticipantType[];
    roundTripOnly?: boolean;
    schema: ObjectSchema<LiaisonSearch>;
};

export function LiaisonForm({
    defaultValues,
    dense = false,
    departureLocations,
    isLoading = false,
    onSubmit,
    participantTypes,
    roundTripOnly = false,
    schema,
}: LiaisonFormProps) {
    const { t } = useTranslation();

    return (
        <Form
            onSubmit={onSubmit}
            options={{ defaultValues }}
            schema={schema}
            schemaOptions={{ stripUnknown: true }}
        >
            <Stack gap={dense ? 3 : 5}>
                <Stack gap={2}>
                    {roundTripOnly ? (
                        <FormHidden defaultValue={TripType.ROUNDTRIP} name="trip-type" />
                    ) : (
                        <LiaisonFormTripType />
                    )}
                    <LiaisonFormTravel departureLocations={departureLocations} />
                    <LiaisonFormDates />
                </Stack>

                <Divider />
                <Stack gap={dense ? 2 : 3}>
                    {participantTypes.map((type) => (
                        <FormNumberStepper
                            key={type.id}
                            defaultValue={type.quantite_min}
                            helperText={t("format.ageRange", {
                                value: { min: type.age_min, max: type.age_max },
                            })}
                            label={type.label}
                            max={type.quantite_max}
                            min={type.quantite_min}
                            name={`participants.${type.id}`}
                        />
                    ))}
                    <FormError name="participants.root" />
                </Stack>
                <FormHidden name="type_produit" />
                <Button isLoading={isLoading} type="submit">
                    {t("Rechercher")}
                </Button>
            </Stack>
        </Form>
    );
}
