export type Option = {
    id: string;
    description?: string;
    description_courte?: string;
    images?: [];
    label: string;
    produit: OptionTariff[];
};

type OptionTariff = {
    type_participant: string[];
    id: string;
    label: string;
    prix?: number;
    dispo_max: number;
    type: "quantite";
    value: number;
};

export type OptionDetails = OptionQuantity | OptionPercentage;

export enum ProductUnit {
    QUANTITY = "1",
    PERCENTAGE = "3",
}

export enum ProductAPI {
    NONE = "0",
    MEETCH = "1",
    GET_YOUR_GUIDE = "2",
}

interface BaseOption {
    id: string;
    nom: string;
    description: string;
    produit_unite: ProductUnit;
    produit_api: ProductAPI;
    choix_unique: boolean;
    obligatoire: boolean;
}

export interface OptionQuantity extends BaseOption {
    produit_unite: ProductUnit.QUANTITY;
    produit_api: ProductAPI.NONE;
    tarifs: TarifQuantity[];
}

type TarifQuantity = {
    id: string;
    nom: string;
    prix_ttc: number;
    type_participant?: { id: string }[];
};

export interface OptionPercentage extends BaseOption {
    produit_unite: ProductUnit.PERCENTAGE;
    produit_api: ProductAPI.MEETCH;
    tarifs: TarifPercentage[];
}

type TarifPercentage = {
    id: string;
    nom: string;
    pourcentage: number;
};
