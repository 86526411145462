import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../config";

import { Product } from "../../../models";
import { QueryConfig } from "../../../utils/react-query-utils";

async function getProduct(productId: string): Promise<Product> {
    const response = await apiClient.get(`/produits/${productId}`);
    return response.data;
}

export function useGetProduct(productId: string, queryConfig?: QueryConfig<typeof getProduct>) {
    const query = useQuery({
        queryKey: ["products", productId],
        queryFn: () => getProduct(productId),
        ...queryConfig,
    });

    return query;
}
