const ONE_DAY_IN_MS = 86400000;

type StoredItem = {
    expiry: number;
    data: any;
};

type StorageOption = {
    prefix?: string;
};

const defaultOptions = {
    prefix: "resamare_",
};

export const storage = {
    get: <T extends object | string>(
        key: string,
        options: StorageOption = defaultOptions,
    ): T | null => {
        const item = window.localStorage.getItem(`${options.prefix}${key}`);

        if (!item) return null;

        try {
            return JSON.parse(item);
        } catch (error) {
            return item as T;
        }
    },

    set: (key: string, data: any, options: StorageOption = defaultOptions) => {
        window.localStorage.setItem(`${options.prefix}${key}`, JSON.stringify(data));
    },

    remove: (key: string, options: StorageOption = defaultOptions) => {
        window.localStorage.removeItem(`${options.prefix}${key}`);
    },

    setWithExpiry: (key: string, data: any, ttl = ONE_DAY_IN_MS, options?: StorageOption) => {
        const now = new Date();
        const item = { data, expiry: now.getTime() + ttl };
        storage.set(key, JSON.stringify(item), options);
    },

    getWithExpiry: <T extends object | string>(key: string, options?: StorageOption): T | null => {
        const savedItem = storage.get<string>(key, options);
        if (!savedItem) return null;

        const item: StoredItem = JSON.parse(savedItem);
        const now = new Date();

        if (now.getTime() > item.expiry) {
            storage.remove(key);
            return null;
        }

        return item.data;
    },
};
