import { Tab as MuiTab } from "@mui/material";
import { Link, Path } from "react-router-dom";

import styles from "./Tabs.module.scss";

type TabLinkProps = {
    label: string;
    to: string | Partial<Path>;
    value: string;
};

export function TabLink({ label, to, value, ...rest }: TabLinkProps) {
    return (
        <MuiTab
            className={styles.tab}
            component={Link}
            label={label}
            to={to}
            value={value}
            {...rest}
        />
    );
}
