import { Card as MuiCard, CardProps as MuiCardProps } from "@mui/material";

type CardProps = Pick<MuiCardProps, "className" | "elevation" | "style" | "variant"> & {
    children: React.ReactNode;
};

export function Card({
    children,
    className,
    elevation = 2,

    style,
    variant = "elevation",
}: CardProps) {
    return (
        <MuiCard
            className={className}
            elevation={variant === "elevation" ? elevation : undefined}
            style={style}
            variant={variant}
        >
            {children}
        </MuiCard>
    );
}

export default Card;
