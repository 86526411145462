import {
    Button,
    Divider,
    Form,
    FormDate,
    FormError,
    FormHidden,
    FormNumberStepper,
    Stack,
} from "@resamare/ui";
import { ObjectSchema } from "yup";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "@resamare/translation";
import { ComponentProps } from "react";
import { dt } from "@resamare/functions";
import { ParticipantType } from "@resamare/models";
import { NoResultFoundModal } from "./NoResultFoundModal/NoResultFoundModal";
import { ExcursionSearch } from "../../../search";
import { useWidgetAttributes } from "../../../widget-attributes";

export type ExcursionFormProps = {
    autoSubmitOnChange?: ComponentProps<typeof Form>["autoSubmitOnChange"];
    availableDates?: Date[];
    blockedDates?: Date[];
    defaultValues: Partial<ExcursionSearch>;
    dense?: boolean;
    isCalendarOpen?: boolean;
    isLoading?: boolean;
    onSubmit: SubmitHandler<ExcursionSearch>;
    participantTypes: ParticipantType[];
    schema: ObjectSchema<ExcursionSearch>;
};

export function ExcursionForm({
    autoSubmitOnChange,
    availableDates,
    blockedDates,
    defaultValues,
    dense = false,
    isCalendarOpen = false,
    isLoading,
    onSubmit,
    participantTypes,
    schema,
}: ExcursionFormProps) {
    const { t } = useTranslation();
    const attributes = useWidgetAttributes();

    return (
        <Form
            autoSubmitOnChange={autoSubmitOnChange}
            onSubmit={onSubmit}
            options={{ defaultValues }}
            schema={schema}
            schemaOptions={{ stripUnknown: true }}
        >
            <Stack gap={dense ? 3 : 5}>
                <FormDate
                    availableDates={availableDates}
                    blockedDates={blockedDates}
                    inline={isCalendarOpen}
                    label="Date"
                    locale={attributes.langue}
                    minDate={dt.now()}
                    name="date_deb"
                />
                <Divider />
                <Stack gap={3}>
                    {participantTypes.map((type) => (
                        <FormNumberStepper
                            key={type.id}
                            defaultValue={type.quantite_min}
                            helperText={t("format.ageRange", {
                                value: { min: type.age_min, max: type.age_max },
                            })}
                            label={type.label}
                            max={type.quantite_max}
                            min={type.quantite_min}
                            name={`participants.${type.id}`}
                        />
                    ))}

                    <FormError name="participants.root" />
                </Stack>
                {defaultValues.produit_id ? <FormHidden name="produit_id" /> : null}
                {defaultValues.type_produit ? <FormHidden name="type_produit" /> : null}
                <Button isLoading={isLoading} size={dense ? "small" : "medium"} type="submit">
                    {t("Rechercher")}
                </Button>
            </Stack>
            <NoResultFoundModal />
        </Form>
    );
}
