import { NavigateOptions, To, useNavigate } from "react-router-dom";
import { usePreviousLocation } from "./usePreviousLocation";

export function useNavigateWithPrevious() {
    const navigateFunction = useNavigate();
    const previousLocation = usePreviousLocation();

    const navigate = (to: To, options?: NavigateOptions) => {
        const { state, ...restOptions } = options || {};
        navigateFunction(to, { state: { previousLocation, ...state }, ...restOptions });
    };

    return navigate;
}
