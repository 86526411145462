import { Stack, Typography } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";
import { useCallback } from "react";
import { flat } from "@resamare/functions";
import { createSearchParams } from "react-router-dom";
import { Package } from "@resamare/models";
import { PackageItem } from "./PackageItem";
import { usePrefetchDatesAvailable } from "../../dates-available";
import { useNavigateWithPrevious } from "../../../hooks";
import { Path } from "../../../routes";
import { PackageSearch, useSearchContext } from "../../search";

type PackagesListProps = {
    packages: Package[];
};

export function PackagesList({ packages }: PackagesListProps) {
    const { search } = useSearchContext<PackageSearch>() as { search: PackageSearch };
    const navigate = useNavigateWithPrevious();
    const { t } = useTranslation();

    const handleClick = useCallback((packageItem: Package) => {
        navigate({
            pathname: Path.PACKAGE_COMPOSER,
            search: `${createSearchParams({ packageId: packageItem.id.toString(), participants: JSON.stringify(search?.participants) })}`,
        });
    }, []);

    const productIds = flat(packages.map((pkg) => pkg.produits.map((product) => product.id)));
    usePrefetchDatesAvailable(productIds);

    return (
        <Stack gap={3}>
            <Typography fontWeight={600}>{t("résultat", { count: packages.length })}</Typography>

            {packages.map((packageItem) => (
                <PackageItem key={packageItem.id} onClick={handleClick} packageItem={packageItem} />
            ))}
        </Stack>
    );
}
