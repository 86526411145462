import { dt, formatCurrency } from "@resamare/functions";
import { Spinner, Stack, Typography } from "@resamare/ui";
import { useOptionsTotal } from "../hooks";
import { useGetOptions } from "../api";
import { SessionSearch } from "../../../models";

type OptionsTotalProps = {
    participants: Record<string, number>;
    session: SessionSearch;
};

export function OptionsTotal({ participants, session }: OptionsTotalProps) {
    const optionsQuery = useGetOptions({
        date: dt.fromISO(dt.removeIsoOffset(session.date_depart)),
    });

    const totalOptions = useOptionsTotal(optionsQuery.data || [], participants);

    if (optionsQuery.isPending) return <Spinner />;

    return (
        <Stack alignItems="flex-start">
            <Typography variant="text-sm">Total</Typography>
            <Stack alignItems="baseline" direction="row" gap={1}>
                <Typography variant="title-sm">
                    {formatCurrency(session.prix_total + totalOptions)}
                </Typography>
            </Stack>
        </Stack>
    );
}
