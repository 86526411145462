import { Box, Select as MuiSelect } from "@mui/material";
import { isEmpty } from "@resamare/functions";
import { FormControl, FormHelperText, FormInputProps, FormLabel } from "../_partials";
import { Stack } from "../../Layout";
import { SelectMultipleItem } from "./SelectMultipleItem";
import { Button, ChevronBottomIcon, CloseIcon } from "../../General";
import { Chip } from "../../DataDisplay";
import { useVisibleValues } from "./useVisibleValues";

import styles from "./SelectMultipleField.module.scss";

export type SelectOption = {
    label: string;
    value: string;
};

export interface SelectMultipleFieldProps
    extends Pick<FormInputProps, "inputRef" | "name" | "placeholder"> {
    helperText?: string;
    isDisabled?: boolean;
    isError?: boolean;
    label: string;
    options: SelectOption[];
    onChange: (value: string[]) => void;
    value: string[];
}

export function SelectMultipleField({
    helperText = "",
    inputRef,
    isDisabled = false,
    isError = false,
    label,
    name,
    onChange,
    options,
    placeholder = "Aucun élément sélectionné",
    value = [],
}: SelectMultipleFieldProps) {
    const { containerRef, visibleValues, hiddenCount } = useVisibleValues(value, options);

    const deleteValue = (valueToDelete: string) => {
        const newValues = value.filter((v) => v !== valueToDelete);
        onChange(newValues);
    };

    return (
        <FormControl isError={isError}>
            <Stack gap={0.5}>
                <FormLabel htmlFor={name} id={name}>
                    {label}
                </FormLabel>
                <div ref={containerRef} className={styles["select-grid"]}>
                    <MuiSelect
                        IconComponent={ChevronBottomIcon}
                        MenuProps={{
                            PaperProps: { sx: { maxHeight: 360 } },
                        }}
                        className={`${styles["select-root"]} ${isDisabled ? styles["select--is-disabled"] : ""} ${!isEmpty(value) ? styles["select--is-filled"] : ""}`}
                        data-testid={name}
                        disabled={isDisabled}
                        displayEmpty
                        id={name}
                        inputProps={{ className: styles["select"] }}
                        inputRef={inputRef}
                        multiple
                        name={name}
                        onChange={(e) => {
                            const newValue =
                                e.target.value === "string"
                                    ? e.target.value.split(",")
                                    : e.target.value;
                            onChange([...newValue]);
                        }}
                        renderValue={() =>
                            isEmpty(value) ? (
                                placeholder
                            ) : (
                                <Box sx={{ display: "flex", gap: 0.5, maxWidth: "100%" }}>
                                    {visibleValues.map((v) => (
                                        <Chip
                                            key={v}
                                            label={
                                                options.find((option) => option.value === v)?.label
                                            }
                                            onClick={() => deleteValue(v)}
                                            onDelete={() => deleteValue(v)}
                                            variant="filled"
                                        />
                                    ))}
                                    {visibleValues.length === 0 ? (
                                        <Chip label={`${hiddenCount} éléments sélectionnés`} />
                                    ) : null}
                                    {visibleValues.length !== 0 && hiddenCount > 0 && (
                                        <Chip
                                            color="primary"
                                            label={`+${hiddenCount}`}
                                            variant="filled"
                                        />
                                    )}
                                </Box>
                            )
                        }
                        value={value}
                        variant="outlined"
                    >
                        {options.map((option) => (
                            <SelectMultipleItem key={option.value} value={option.value}>
                                {option.label}
                            </SelectMultipleItem>
                        ))}
                    </MuiSelect>
                    <Button
                        aria-label={`reset ${name}`}
                        className={styles["select__reset-button"]}
                        disabled={value.length === 0}
                        onClick={() => onChange([])}
                        size="small"
                        variant="outlined"
                    >
                        <CloseIcon size="small" />
                    </Button>
                </div>
                {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
            </Stack>
        </FormControl>
    );
}
