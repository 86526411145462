import { Chip as MuiChip, ChipProps as MuiChipProps } from "@mui/material";
import { Typography } from "../../General";

import styles from "./Chip.module.scss";

type ChipProps = Pick<
    MuiChipProps,
    "color" | "label" | "onClick" | "onDelete" | "size" | "variant"
>;

export function Chip({ color, label, onClick, onDelete, size, variant = "outlined" }: ChipProps) {
    return (
        <MuiChip
            classes={{ label: styles["label"] }}
            clickable={!!onClick}
            color={color}
            label={
                <Typography component="span" variant={size === "small" ? "text-xs" : "text-sm"}>
                    {label}
                </Typography>
            }
            onClick={onClick}
            onDelete={onDelete}
            onMouseDown={(e) => {
                if (!onClick && !onDelete) return;
                e.stopPropagation();
            }}
            size={size}
            variant={variant}
        />
    );
}
