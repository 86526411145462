import { dt } from "@resamare/functions";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../config";

export type DatesAvailableStock = {
    date_dispo: { date: string; stock: number }[] | null;
    date_bloquee: { date: string; stock: number }[] | null;
};

type GetDatesAvailableStockDTO = {
    productId: string;
};

async function getDatesAvailableStock(
    params: GetDatesAvailableStockDTO,
): Promise<DatesAvailableStock> {
    const now = dt.now();

    const response = await apiClient.get(`/dateDispoStock`, {
        params: {
            date_deb: dt.toIsoDate(now),
            date_fin: dt.toIsoDate(dt.add(now, { years: 1 })),
            produit_id: params.productId,
        },
    });
    return response.data;
}

export function useGetDatesAvailableStock(params: GetDatesAvailableStockDTO) {
    const query = useQuery({
        queryKey: ["dates-available-stock", params],
        queryFn: () => getDatesAvailableStock(params),
    });

    return query;
}
