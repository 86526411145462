import { useRef, useState } from "react";
import { useTranslation } from "@resamare/translation";
import { Button, Drawer, Stack } from "@resamare/ui";
import { useMultipleFormSubmitter } from "@resamare/react-hooks";
import { NavibusCard } from "../components/NavibusCard/NavibusCard";
import { NavibusCardTripDetails } from "../components/NavibusCard/NavibusCardTripDetails";
import { NavibusOptions } from "./NavibusOptions";
import { Liaison } from "../../../../models";
import { NavibusSearch, useSearchContext } from "../../../search";
import { useGetPackagePrices } from "../../../packages";
import { OverlayFooter, OverlayHeader } from "../../../../components";
import { ModalLayout } from "../../../../layouts";
import { useCreateCartLineAndNavigate } from "../../../options";

type NavibusCartLine = {
    lieu_id: string;
    lieu_destination_id: string;
    participants: Record<string, number>;
    session_id: string;
};

type NavibusItemProps = {
    liaisons: Liaison[];
};

export function NavibusItem({ liaisons }: NavibusItemProps) {
    const { t } = useTranslation();

    const { search } = useSearchContext<NavibusSearch>() as { search: NavibusSearch };
    const [isOpen, setIsOpen] = useState(false);

    const pricesQuery = useGetPackagePrices({
        packageId: search.produit_id,
        participants: search.participants,
        date_deb: search.date_deb,
        date_fin: search.date_deb,
        lieu_depart: search.itinerary[0],
        lieu_destination: search.itinerary[search.itinerary.length - 1],
    });

    const { formRefs, submitForms } = useMultipleFormSubmitter();
    const lines = useRef<NavibusCartLine[]>([]);
    const { handleCreateCartLine, isLoading } = useCreateCartLineAndNavigate();
    const handleOnSubmit = (data: Record<string, number>, index: number) => {
        const liaison = liaisons[index];
        lines.current[index] = {
            lieu_id: liaison.lieux_depart[0].value,
            lieu_destination_id: liaison.lieux_destination[0].value,
            participants: search.participants,
            session_id: liaison.id,
            ...data,
        };

        if (lines.current.length === liaisons.length) {
            handleCreateCartLine(lines.current);
        }
    };

    const handleOnClose = () => {
        lines.current = [];
        setIsOpen(false);
    };

    return (
        <>
            <NavibusCard
                liaisons={liaisons}
                onClick={() => setIsOpen(true)}
                total={pricesQuery.data?.prix_apartirde}
            >
                <NavibusCardTripDetails liaisons={liaisons} />
            </NavibusCard>

            <Drawer isOpen={isOpen} maxWidth={560} onClose={handleOnClose}>
                <OverlayHeader onClose={handleOnClose}>{t("Options")}</OverlayHeader>
                <ModalLayout>
                    <Stack gap={10}>
                        {liaisons.map((liaison, index) => (
                            <NavibusOptions
                                key={liaison.id}
                                ref={(el) => {
                                    formRefs.current[index] = el;
                                }}
                                liaison={liaison}
                                onSubmit={(data) => handleOnSubmit(data, index)}
                                step={liaisons.length > 1 ? index + 1 : null}
                            />
                        ))}
                    </Stack>
                </ModalLayout>
                <OverlayFooter>
                    <Stack direction="row" justifyContent="flex-end">
                        <Button isLoading={isLoading} onClick={submitForms}>
                            {t("Suivant")}
                        </Button>
                    </Stack>
                </OverlayFooter>
            </Drawer>
        </>
    );
}
