import {
    useSearchContext,
    useGetSessionsSearch,
    ExcursionSearch,
    ExcursionFormContainer,
} from "@resamare/widgets-shared";
import { isEqual } from "@resamare/functions";

export function ExcursionForm() {
    const { search, setSearch } = useSearchContext<ExcursionSearch>();
    const sessionsQuery = useGetSessionsSearch({ params: search });

    const onSubmit = (data: ExcursionSearch) => {
        if (isEqual(data, search)) {
            sessionsQuery.refetch();
        }
        setSearch(data);
    };

    return <ExcursionFormContainer isLoading={sessionsQuery.isFetching} onSubmit={onSubmit} />;
}
