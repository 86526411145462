import { DialogProps as MuiDialogProps, Dialog as MuiDialog } from "@mui/material";

interface DialogProps
    extends Pick<
        MuiDialogProps,
        "children" | "container" | "fullWidth" | "onClose" | "onTransitionExited"
    > {
    isOpen: boolean;
}

export function Dialog({
    children,
    container,
    fullWidth,
    isOpen,
    onClose,
    onTransitionExited,
}: DialogProps) {
    return (
        <MuiDialog
            container={container}
            fullWidth={fullWidth}
            onClose={onClose}
            onTransitionExited={onTransitionExited}
            open={isOpen}
            scroll="body"
        >
            {children}
        </MuiDialog>
    );
}
