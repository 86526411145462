/* eslint-disable react/jsx-sort-props */
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import {
    CheckoutConfirmationPage,
    CheckoutFormPage,
    CheckoutProcessLayout,
    CheckoutSummaryPage,
    ExcursionsPage,
    LiaisonsPage,
    NavibusPage,
    OptionsPage,
    PackageComposerPage,
    PackagesPage,
    Path as SharedPath,
} from "@resamare/widgets-shared";
import { DefaultRoute } from "./DefaultRoute";
import { SearchLayout } from "../layouts";
import { Path } from "./pathnames";

export function AppRoutes() {
    const location = useLocation();
    const previousLocation = location.state?.previousLocation;

    return (
        <>
            <Routes location={previousLocation || location}>
                <Route path="/" element={<DefaultRoute />} />
                <Route element={<SearchLayout />}>
                    <Route
                        path={`${Path.EXCURSIONS}/:productTypeId`}
                        element={<ExcursionsPage />}
                    />
                    <Route path={`${Path.LIAISONS}/:productTypeId`} element={<LiaisonsPage />} />
                    <Route path={`${Path.PACKAGES}/:productTypeId`} element={<PackagesPage />} />
                    <Route path={`${Path.NAVIBUS}/:productTypeId`} element={<NavibusPage />} />
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            {previousLocation && (
                <Routes>
                    <Route element={<CheckoutProcessLayout />}>
                        <Route path={SharedPath.OPTIONS} element={<OptionsPage />} />
                        <Route
                            path={SharedPath.PACKAGE_COMPOSER}
                            element={<PackageComposerPage />}
                        />
                        <Route
                            path={SharedPath.CHECKOUT_SUMMARY}
                            element={<CheckoutSummaryPage />}
                        />
                        <Route path={SharedPath.CHECKOUT_FORM} element={<CheckoutFormPage />} />
                        <Route
                            path={SharedPath.CHECKOUT_CONFIRMATION}
                            element={<CheckoutConfirmationPage />}
                        />
                    </Route>
                </Routes>
            )}
        </>
    );
}
