import { useController } from "react-hook-form";
import { dt } from "@resamare/functions";
import { FieldWrapper } from "../FieldWrapper/FieldWrapper";
import { DateField, DateFieldProps } from "./DateField";

interface FormDateProps
    extends Pick<
        DateFieldProps,
        | "availableDates"
        | "blockedDates"
        | "dayInfo"
        | "helperText"
        | "inline"
        | "label"
        | "locale"
        | "maxDate"
        | "minDate"
        | "name"
        | "portalId"
    > {
    defaultValue?: Date;
}

export function FormDate({
    availableDates,
    blockedDates,
    dayInfo,
    defaultValue = dt.now(),
    helperText,
    inline,
    label,
    locale,
    maxDate,
    minDate,
    name,
    portalId,
}: FormDateProps) {
    const { field, fieldState } = useController({
        name,
        defaultValue: minDate && dt.isAfter(minDate, dt.now()) ? minDate : defaultValue,
    });

    return (
        <FieldWrapper name={name}>
            <DateField
                availableDates={availableDates}
                blockedDates={blockedDates}
                dayInfo={dayInfo}
                helperText={helperText}
                inline={inline}
                inputRef={field.ref}
                isError={!!fieldState.error}
                label={label}
                locale={locale}
                maxDate={maxDate}
                minDate={minDate}
                name={name}
                onChange={field.onChange}
                portalId={portalId}
                value={field.value}
            />
        </FieldWrapper>
    );
}
