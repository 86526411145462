export enum SharedPath {
    SEARCH = "/search",

    PARTICIPANTS = "/participants",
    PARTICIPANTS_PACKAGE = "/participants-package",
    OPTIONS = "/options",
    ADDITIONNAL_OPTIONS = "/additionnal-options",
    PACKAGE_COMPOSER = "/package-composer",

    CHECKOUT_SUMMARY = "/checkout-summary",
    CHECKOUT_FORM = "/checkout-form",
    CHECKOUT_CONFIRMATION = "/checkout-confirmation",
}
