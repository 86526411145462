import { SubmitHandler } from "react-hook-form";
import { PackageChoiceOptions } from "./PackageChoiceOptions";
import { ExcursionOptionsValues } from "../../../activity-types/excursion";
import { SessionSearch } from "../../../../models";
import { usePrefetchSessionOptionsForm } from "../../../sessions";
import { useSearchParamsPackageComposer } from "../../hooks/useSearchParamsPackageComposer";

type PackageChoiceSessionProps = {
    onSubmit: SubmitHandler<ExcursionOptionsValues>;
    sessions: SessionSearch[];
};

export function PackageChoiceSession({ onSubmit, sessions }: PackageChoiceSessionProps) {
    const { participants } = useSearchParamsPackageComposer();
    const sessionIds = sessions.map((session) => session.id);

    usePrefetchSessionOptionsForm(sessionIds, { participants });

    const firstBookableSession = sessions.find((session) => session.dispo_vente) || sessions[0];

    return (
        <PackageChoiceOptions
            defaultSession={firstBookableSession}
            defaultValues={{
                session_id: firstBookableSession.id,
                hour: firstBookableSession.heure_depart.value,
                lieu_id: firstBookableSession.lieux_depart[0].value.toString(),
                participants,
            }}
            onSubmit={onSubmit}
            sessions={sessions}
        />
    );
}
