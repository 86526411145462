import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Package } from "@resamare/models";
import { apiClient } from "../../../config";
import { QueryConfig } from "../../../utils/react-query-utils";

type GetPackagesDTO = {
    type_produit?: number;
};

async function getPackages(params?: GetPackagesDTO): Promise<Package[]> {
    const { data } = await apiClient.get("/packages", {
        params: { type_produit: params?.type_produit },
    });
    return data;
}

export function useGetPackages(
    params?: GetPackagesDTO,
    queryConfig?: QueryConfig<typeof getPackages>,
) {
    const query = useQuery({
        queryKey: ["packages", { ...params }],
        queryFn: () => getPackages(params),
        placeholderData: keepPreviousData,
        ...queryConfig,
    });

    return query;
}
