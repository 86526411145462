import { Alert, Button, Stack, Typography } from "@resamare/ui";
import { formatCurrency } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";
import { useNavigate } from "react-router-dom";
import { AsideLayout } from "../../../../layouts";
import { Cart } from "../../../../models";
import { usePreviousLocation } from "../../../../hooks";

import { Path } from "../../../../routes";
import { useCartIsAvailable } from "../../../cart";

type OrderSummaryAsideProps = {
    cart: Cart;
};

export function OrderSummaryAside({ cart }: OrderSummaryAsideProps) {
    const navigate = useNavigate();
    const previousLocation = usePreviousLocation();
    const { t } = useTranslation();

    const total = parseFloat(cart.total_panier);

    const isAvailable = useCartIsAvailable(cart);

    return (
        <AsideLayout>
            <Stack gap={3}>
                <Stack gap={3}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="title-xs">{t("Montant total")}</Typography>
                        <Typography variant="title-xs">{formatCurrency(total)}</Typography>
                    </Stack>

                    <div>
                        <Button
                            disabled={!isAvailable}
                            fullWidth
                            onClick={() =>
                                navigate(Path.CHECKOUT_FORM, { state: { previousLocation } })
                            }
                        >
                            {t("Passer la commande")}
                        </Button>
                        {!isAvailable ? (
                            <Typography align="center" color="error">
                                {t("Supprimez les produits indisponibles pour continuer")}
                            </Typography>
                        ) : null}
                    </div>
                    <Button onClick={() => navigate(previousLocation)} variant="outlined">
                        {t("Continuer mes achats")}
                    </Button>
                </Stack>

                <Alert severity="info" title={t("Code promo / Carte cadeau")}>
                    {t(
                        "Si vous possédez un code promo ou une carte cadeau, vous pourrez l'utiliser à l'étape suivante.",
                    )}
                </Alert>
            </Stack>
        </AsideLayout>
    );
}
