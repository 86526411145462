import { ensure } from "@resamare/functions";
import { useLocation } from "react-router-dom";
import { usePrefetchSessionOptionsForm } from "../../sessions";
import { OptionsContainer } from "../containers";
import { useCreateCartLineAndNavigate, useSearchParamsOptions } from "../hooks";
import { SessionSearch } from "../../../models";

export function OptionsPage() {
    const { sessionIds, participants } = useSearchParamsOptions();
    const { state } = useLocation();
    const sessions: SessionSearch[] = ensure(state.sessions, { state });
    usePrefetchSessionOptionsForm(sessionIds, { participants });

    const firstBookableSession = ensure(
        sessions.find((session) => session?.dispo_vente),
        { sessions, sessionIds },
    );

    const defaultValues = {
        session_id: firstBookableSession.id,
        hour: firstBookableSession.heure_depart.value,
        lieu_id: firstBookableSession.lieux_depart[0].value.toString(),
        participants,
    };

    const { handleCreateCartLine, isLoading } = useCreateCartLineAndNavigate();

    return (
        <OptionsContainer
            defaultValues={defaultValues}
            isLoading={isLoading}
            onSubmit={handleCreateCartLine}
            participants={participants}
            sessions={sessions}
        />
    );
}
