import { unique } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";
import { FormHidden, FormRadio, Typography } from "@resamare/ui";
import { useFormContext, useWatch } from "react-hook-form";
import { useEffect } from "react";
import { SessionSearch } from "../../../../../models";

type DepartureTimeOptionProps = {
    sessions: SessionSearch[];
};

export function DepartureTimeOption({ sessions }: DepartureTimeOptionProps) {
    const { t } = useTranslation();

    const { setValue, getValues } = useFormContext();

    const selectedHour = useWatch({ name: "hour" });
    const selectedSession = sessions.find(({ heure_depart: { value } }) => value === selectedHour);

    useEffect(() => {
        if (!selectedSession) return;
        setValue("session_id", parseInt(selectedSession.id, 10));
        const selectedLocation = getValues("lieu_id");
        const departureIsPresent = selectedSession.lieux_depart.find(
            (location) => location.value.toString() === selectedLocation,
        );
        if (!departureIsPresent) {
            setValue("lieu_id", selectedSession.lieux_depart[0].value.toString());
        }
    }, [getValues, selectedSession, setValue]);

    const departureTimes = sessions.map((session) => {
        let description = session.stock
            ? t("Plus que x places disponibles", { count: session.stock })
            : "";

        if (session.dispo_vente === false) {
            description = t("Vente en ligne désactivée");
        }

        return {
            ...session.heure_depart,
            disabled: session.dispo_vente === false,
            description,
        };
    });

    const departureTimesOptions = unique(departureTimes, (time) => time.value);

    return departureTimesOptions.length > 1 ? (
        <FormRadio label={t("Heure de départ")} name="hour" options={departureTimesOptions} />
    ) : (
        <div>
            <Typography variant="label">{t("Heure de départ")}</Typography>
            <Typography>{departureTimesOptions[0].label}</Typography>
            {departureTimesOptions[0].description ? (
                <Typography variant="helper-text">
                    {departureTimesOptions[0].description}
                </Typography>
            ) : null}
            <FormHidden name="hour" />
        </div>
    );
}
