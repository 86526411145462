import { Drawer as MuiDrawer, DrawerProps as MuiDrawerProps } from "@mui/material";

import Styles from "./Drawer.module.scss";

interface DrawerProps extends Pick<MuiDrawerProps, "children" | "onClose" | "onTransitionExited"> {
    isOpen: boolean;
    maxHeight?: `${number}%` | number;
    maxWidth?: `${number}%` | number | "fit-content";
    minHeight?: `${number}%` | number;
    position?: "bottom" | "right";
}

export function Drawer({
    children,
    isOpen,
    maxHeight,
    maxWidth,
    minHeight,
    onClose,
    onTransitionExited,
    position = "right",
}: DrawerProps) {
    return (
        <MuiDrawer
            PaperProps={{
                "data-testid": "drawer-content",
                style: { width: "100%", maxHeight, maxWidth, minHeight },
                className: `${Styles[`drawer__content`]} ${
                    Styles[`drawer__content${`--is-${position}`}`]
                }`,
            }}
            anchor={position}
            aria-labelledby="Drawer content"
            aria-modal="true"
            closeAfterTransition
            onClose={onClose}
            onTransitionExited={onTransitionExited}
            open={isOpen}
            role="dialog"
        >
            {children}
        </MuiDrawer>
    );
}
