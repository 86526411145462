import { Spinner, Stack, Typography } from "@resamare/ui";
import { dt, ensure } from "@resamare/functions";
import { useState } from "react";
import { ModalLayout } from "../../../layouts";
import { PackageChoice } from "../components/PackageComposer/PackageChoice";
import { useGetPackages } from "../api/getPackages";
import { useSearchParamsPackageComposer } from "../hooks/useSearchParamsPackageComposer";
import { ExcursionOptionsValues } from "../../activity-types/excursion";
import { useCreateCartLineAndNavigate } from "../../options";

export function PackageComposerPage() {
    const [selectedProducts, setSelectedProducts] = useState<ExcursionOptionsValues[]>([]);
    const [firstDate, setFirstDate] = useState<Date | null>();

    const packagesQuery = useGetPackages();
    const { packageId } = useSearchParamsPackageComposer();

    const { handleCreateCartLine, isLoading } = useCreateCartLineAndNavigate();

    if (packagesQuery.isPending) {
        return (
            <ModalLayout>
                <Spinner />
            </ModalLayout>
        );
    }
    if (!packagesQuery.data) return null;

    const packageItem = ensure(packagesQuery.data.find(({ id }) => id.toString() === packageId));

    const products = packageItem?.produits.flatMap((produit) =>
        Array.from({ length: produit.quantite }, (_, index) => ({
            id: produit.id,
            nom: produit.nom,
            key: `${produit.id}-${index}`,
        })),
    );

    const handleOnEdit = (index: number) => {
        setSelectedProducts(Object.assign([], selectedProducts, { [index]: null }));
    };

    const handleOnSubmit = (data: ExcursionOptionsValues, index: number) => {
        const chosenProducts = [
            ...selectedProducts.slice(0, index),
            { ...data },
            ...selectedProducts.slice(index + 1),
        ];
        setSelectedProducts(chosenProducts);

        if (chosenProducts.filter((v) => v).length === products?.length) {
            handleCreateCartLine(chosenProducts);
        }
    };

    const maxDate =
        firstDate && packageItem?.periode ? dt.add(firstDate, packageItem.periode) : undefined;

    return (
        <ModalLayout>
            <Stack gap={5}>
                <Stack gap={3}>
                    {products.length === 0 ? (
                        <Typography>Aucun produit ne compose ce package</Typography>
                    ) : null}
                    {products?.map((product, index) => (
                        <PackageChoice
                            key={product.key}
                            isCompleted={!!selectedProducts[index]}
                            isDisabled={index !== 0 && !selectedProducts[index - 1]}
                            maxDate={index !== 0 ? maxDate : undefined}
                            minDate={index !== 0 ? firstDate : undefined}
                            onDateChange={index === 0 ? setFirstDate : undefined}
                            onEdit={() => handleOnEdit(index)}
                            onSubmit={(data) => handleOnSubmit(data, index)}
                            product={product}
                            step={products.length === 1 ? null : index + 1}
                        />
                    ))}
                </Stack>
                {isLoading ? (
                    <Stack gap={2}>
                        <Spinner size={40} />
                        <Typography align="center">Création du package en cours...</Typography>
                    </Stack>
                ) : null}
            </Stack>
        </ModalLayout>
    );
}
