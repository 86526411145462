import { Spinner } from "@resamare/ui";
import { flat, isEmpty, unique } from "@resamare/functions";
import { SubmitHandler } from "react-hook-form";
import { PackageChoiceSession } from "./PackageChoiceSession";
import { ExcursionOptionsValues } from "../../../activity-types/excursion";
import { useGetSessionsSearch } from "../../../sessions";
import { NoResultFound } from "../../../../components";
import { useSearchParamsPackageComposer } from "../../hooks/useSearchParamsPackageComposer";

type PackageChoiceSessionsProps = {
    date: Date;
    onDateChange: (date: Date) => void;
    onSubmit: SubmitHandler<ExcursionOptionsValues>;
    productId: number;
};

export function PackageChoiceSessions({
    date,
    onDateChange,
    onSubmit,
    productId,
}: PackageChoiceSessionsProps) {
    const { participants } = useSearchParamsPackageComposer();

    const searchParams = {
        date_deb: date,
        participants,
        produit_id: productId,
    };
    const sessionsQuery = useGetSessionsSearch({ params: searchParams });

    if (sessionsQuery.isFetching) return <Spinner />;
    if (!sessionsQuery.data) return null;

    const sessionsWithoutDuplicates = unique(
        flat(sessionsQuery.data.map((product) => product.sessions)),
        (session) => session.heure_depart.value,
    );

    if (isEmpty(sessionsWithoutDuplicates)) {
        return <NoResultFound onDateClick={onDateChange} search={searchParams} />;
    }

    return <PackageChoiceSession onSubmit={onSubmit} sessions={sessionsWithoutDuplicates} />;
}
