import { Cart } from "../../../models";

/**
 * Hook pour vérifier si toutes les lignes du panier sont disponibles.
 * @param cart - L'objet panier
 * @returns Un booléen indiquant si toutes les lignes du panier sont disponibles
 */
export function useCartIsAvailable(cart: Cart) {
    const allCartLines = [...cart.ligne_package, ...cart.ligne_panier];

    return allCartLines.every((line) => line.dispo);
}
