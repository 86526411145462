import { useEffect, useState, useRef } from "react";
import { SelectOption } from "./SelectMultipleField";

/**
 * useVisibleValues - Un hook personnalisé pour calculer les valeurs visibles dans un conteneur en fonction de l'espace disponible.
 *
 * Ce hook mesure dynamiquement l'espace disponible dans un conteneur pour afficher un nombre limité d'éléments
 * (comme des `Chip`) et calcule combien de valeurs peuvent être affichées tout en renvoyant un compteur des valeurs cachées.
 *
 * @param {string[]} value - Tableau des valeurs sélectionnées.
 * @param {SelectOption[]} options - Tableau des options disponibles, contenant une valeur et un libellé.
 *
 * @returns {{
 *   containerRef: React.RefObject<HTMLDivElement>,
 *   visibleValues: string[],
 *   hiddenCount: number
 * }}
 * - containerRef : Référence à attacher au conteneur pour mesurer sa largeur.
 * - visibleValues : Tableau des valeurs visibles (qui peuvent être affichées dans le conteneur).
 * - hiddenCount : Nombre d'éléments non visibles en raison de l'espace insuffisant.
 *
 */
export function useVisibleValues(value: string[], options: SelectOption[]) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [visibleValues, setVisibleValues] = useState<string[]>([]);
    const [hiddenCount, setHiddenCount] = useState<number>(0);

    useEffect(() => {
        const calculateVisibleValues = () => {
            if (!containerRef.current) return;

            const containerWidth = containerRef.current.offsetWidth;
            let cumulativeWidth = 0;
            const tempVisible: string[] = [];

            // Création d'un conteneur temporaire pour mesurer dynamiquement la largeur des Chip
            const dummyContainer = document.createElement("div");
            document.body.appendChild(dummyContainer);

            value.forEach((v) => {
                const chip = document.createElement("div");
                chip.textContent = options.find((option) => option.value === v)?.label || "";
                chip.style.display = "inline-block";
                chip.style.fontSize = "14px";
                dummyContainer.appendChild(chip);

                const chipWidth = chip.offsetWidth + 45; // Largeur du Chip avec un padding ajusté
                cumulativeWidth += chipWidth;

                // Vérification si l'élément peut tenir dans l'espace restant
                if (cumulativeWidth < containerWidth - 120) {
                    tempVisible.push(v);
                }

                dummyContainer.removeChild(chip);
            });

            document.body.removeChild(dummyContainer);
            setVisibleValues(tempVisible);
            setHiddenCount(value.length - tempVisible.length);
        };

        calculateVisibleValues();
        window.addEventListener("resize", calculateVisibleValues);

        return () => window.removeEventListener("resize", calculateVisibleValues);
    }, [value, options]);

    return { containerRef, visibleValues, hiddenCount };
}
