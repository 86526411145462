import { SessionSearch } from "../../../models";
import { useGetSessionOptionsForm } from "../../sessions";

export function useHasOptionsToShow(
    sessions: SessionSearch[],
    participants: Record<string, number>,
) {
    const optionsQuery = useGetSessionOptionsForm(sessions[0].id, { participants });

    // departure times
    const hasDifferentDepartureTimes =
        sessions.length > 1 &&
        sessions.some(
            (session, _, [firstSession]) =>
                session.heure_depart.value !== firstSession.heure_depart.value,
        );
    if (hasDifferentDepartureTimes) return true;

    // departure locations
    const hasMultipleDepartureLocations = sessions[0].lieux_depart.length > 1;
    if (hasMultipleDepartureLocations) return true;

    // options
    if (optionsQuery.data === undefined) return true;
    const hasOptions = optionsQuery.data.sections.length > 0;
    if (hasOptions) return true;

    return false;
}
