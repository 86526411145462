import { ObjectSchema, object, number } from "yup";
import { ParticipantType } from "@resamare/models";
import { PackageSearch } from "../../search";

export function getPackageSchema(participantTypes: ParticipantType[]) {
    const participantsShape = participantTypes.reduce(
        (acc, type) => ({
            ...acc,
            [type.id]: number()
                .min(
                    type.quantite_min,
                    `${type.label} doit être supérieur ou égal à ${type.quantite_min}`,
                )
                .max(
                    type.quantite_max,
                    `${type.label} doit être inférieur ou égal à ${type.quantite_max}`,
                ),
        }),
        {},
    );

    const schema: ObjectSchema<PackageSearch> = object<PackageSearch>().shape({
        participants: object()
            .transform((value) => ({ ...value }))
            .shape(participantsShape)
            .test({
                test: (value: PackageSearch["participants"]) =>
                    Object.values(value).some((v) => v > 0),
                message: "Veuillez sélectionner des participants",
            }),

        type_produit: number().positive().integer().required(),
    });

    return schema;
}
