import { NavibusFormContainer, NavibusSearch, useSearchContext } from "@resamare/widgets-shared";
import { useIsFetching } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export function NavibusForm() {
    const { productTypeId } = useParams() as { productTypeId: string };
    const { setSearch } = useSearchContext<NavibusSearch>();
    const isFetchingSessions = useIsFetching({ queryKey: ["navibus-sessions"] });

    const handleOnSubmit = (data: NavibusSearch) => {
        setSearch(data);
    };

    return (
        <NavibusFormContainer
            isLoading={isFetchingSessions > 0}
            onSubmit={handleOnSubmit}
            productTypeId={productTypeId}
        />
    );
}
