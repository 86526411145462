import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { dt } from "@resamare/functions";

import { apiClient } from "../../../config";
import { Product } from "../../../models";

type GetSessionsSearchDTO = {
    date_deb: Date;
    participants: Record<string, number>;
    type_produit?: number;
    produit_id?: number | string;
};

async function getSessionsSearch(params: GetSessionsSearchDTO): Promise<Product[]> {
    const { data } = await apiClient.get("/recherche", {
        params: {
            ...params,
            date_deb: dt.toIsoDate(params.date_deb),
            participants: JSON.stringify(params.participants),
        },
    });
    return data.liste_produit;
}

type UseGetSessionsSearchOptions = {
    params: GetSessionsSearchDTO | null;
};

export function useGetSessionsSearch({ params }: UseGetSessionsSearchOptions) {
    const query = useQuery({
        queryKey: ["sessions-search", { ...params }],
        queryFn: () => getSessionsSearch(params as GetSessionsSearchDTO),
        enabled: !!params,
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: false,
        structuralSharing: false,
        staleTime: 0,
    });

    return query;
}
