import { useTranslation } from "@resamare/translation";
import { ParticipantType, ProductSection } from "@resamare/models";
import {
    Button,
    Divider,
    Form,
    FormDate,
    FormError,
    FormHidden,
    FormNumberStepper,
    Stack,
} from "@resamare/ui";
import { ObjectSchema } from "yup";
import { dt } from "@resamare/functions";
import { NavibusSearch } from "../../../search";
import { NavibusItineraryPlanner } from "./NavibusItineraryPlanner";

export type NavibusFormProps = {
    defaultValues?: Partial<NavibusSearch>;
    isLoading?: boolean;
    maxDate?: Date | null;
    minDate?: Date | null;
    onSubmit: (data: NavibusSearch) => void;
    participantTypes: ParticipantType[];
    productSections: ProductSection[];
    productTypeId: string;
    schema: ObjectSchema<NavibusSearch>;
};

export function NavibusForm({
    defaultValues,
    isLoading,
    maxDate,
    minDate = dt.now(),
    onSubmit,
    participantTypes,
    productSections,
    productTypeId,
    schema,
}: NavibusFormProps) {
    const { t } = useTranslation();

    return (
        <Form
            onSubmit={onSubmit}
            options={{ defaultValues }}
            schema={schema}
            schemaOptions={{ stripUnknown: true }}
        >
            <Stack gap={5}>
                <Stack gap={2}>
                    <FormDate label="Date" maxDate={maxDate} minDate={minDate} name="date_deb" />
                    <NavibusItineraryPlanner
                        productSections={productSections}
                        productTypeId={productTypeId}
                    />
                </Stack>

                <Divider />
                <Stack gap={3}>
                    {participantTypes.map((type) => (
                        <FormNumberStepper
                            key={type.id}
                            defaultValue={type.quantite_min}
                            helperText={t("format.ageRange", {
                                value: { min: type.age_min, max: type.age_max },
                            })}
                            label={type.label}
                            max={type.quantite_max}
                            min={type.quantite_min}
                            name={`participants.${type.id}`}
                        />
                    ))}
                    <FormError name="participants.root" />
                </Stack>
                <FormHidden name="produit_id" />
                <Button isLoading={isLoading} type="submit">
                    {t("Rechercher")}
                </Button>
            </Stack>
        </Form>
    );
}
